import { useMutation } from '@apollo/client';
import { Button, DatePicker, Select, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { Export } from 'phosphor-react';
import React, { useMemo, useState } from 'react';
import {
  DONATION_TYPES,
  LIST_TYPES,
  MODULES,
  PAYMENT_STATUS,
  STATUS_COLORS,
  SUBSCRIPTION_STATUS
} from '../../common/constants';
import Avatar from '../../components/Avatar';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import { CANCEL_DONATION, EXPORT_DONATION } from './graphql/Mutations';
import { GET_DONATIONS } from './graphql/Queries';

const { RangePicker } = DatePicker;

const variablesSelector = ({
  limit,
  offset,
  search,
  sortField,
  sortOrder,
  type,
  dateRange
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    type,
    dateRange
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = ({ donationsAdmin }) => ({
  data: donationsAdmin?.donations ?? [],
  count: donationsAdmin?.count ?? 0
});

const Action = ({ id, paymentStatus, type, subscriptionStatus, refresh }) => {
  const [cancelDonation, { loading }] = useMutation(CANCEL_DONATION);

  const handleClick = () => {
    cancelDonation({
      variables: {
        id
      }
    }).then(() => {
      refresh();
    });
  };

  return (
    paymentStatus === PAYMENT_STATUS.SUCCESS &&
    type !== DONATION_TYPES.ONE_TIME &&
    subscriptionStatus !== SUBSCRIPTION_STATUS.CANCELLED && (
      <Button
        loading={loading}
        onClick={handleClick}
        type="text"
        className="text-btn"
      >
        Cancel
      </Button>
    )
  );
};

const columns = ({ refresh }) => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: (_, { createdAt }) => moment(createdAt).format('D/M/YY')
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (val) => `$${val?.toFixed(2)}`
  },
  {
    title: 'Frequency',
    dataIndex: 'type',
    render: (val) =>
      ({
        [DONATION_TYPES.ONE_TIME]: 'One Time',
        [DONATION_TYPES.MONTHLY]: 'Monthly',
        [DONATION_TYPES.ANNUALLY]: 'Annually'
      }[val] ?? '')
  },
  {
    title: 'Avatar',
    dataIndex: 'user',
    width: 100,
    render: (_, { user: { firstName, lastName, profileImage } }) => (
      <Avatar src={profileImage} firstName={firstName} lastName={lastName} />
    )
  },
  {
    title: 'F Name',
    dataIndex: 'user',
    ellipsis: true,
    render: (_, { user: { firstName } }) => firstName
  },
  {
    title: 'L Name',
    dataIndex: 'user',
    ellipsis: true,
    render: (_, { user: { lastName } }) => lastName
  },
  {
    title: 'Email',
    dataIndex: 'user',
    ellipsis: true,
    render: (_, { user: { email } }) => email
  },
  {
    title: 'Contact Number',
    dataIndex: 'user',
    ellipsis: true,
    render: (_, { user }) => {
      if (!user?.contactNumber) return '-';
      return `${user?.countryCode ?? ''} ${user?.contactNumber}`;
    }
  },
  {
    title: 'Stripe ID',
    dataIndex: 'transactionId',
    render: (val) => `#${val}`
  },
  {
    title: 'Key',
    dataIndex: 'key'
  },
  {
    title: 'Payment Status',
    dataIndex: 'paymentStatus',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {value}
      </Tag>
    )
  },
  {
    title: 'Subscription Status',
    dataIndex: 'subscriptionStatus',
    render: (value) => (
      <Tag className="m-0 custom-tag" color={STATUS_COLORS[value]}>
        {value}
      </Tag>
    )
  },
  {
    title: ' ',
    dataIndex: 'id',
    render: (_, record) => <Action refresh={refresh} {...record} />
  }
];

const TYPE_OPTIONS = [
  { label: 'One Time', value: DONATION_TYPES.ONE_TIME },
  {
    label: 'Monthly',
    value: DONATION_TYPES.MONTHLY
  },
  {
    label: 'Annually',
    value: DONATION_TYPES.ANNUALLY
  }
];

const Donations = () => {
  const [type, setType] = useState(null);

  const [selectedDate, setSelectedDate] = useState({
    after: null,
    before: null
  });

  const [exportDonation, { loading: exportLoading }] = useMutation(
    EXPORT_DONATION,
    {
      onError: () => {}
    }
  );

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: [
        { name: 'Created At', value: 'createdAt' },
        { name: 'Type', value: 'type' },
        { name: 'Amount', value: 'amount' }
      ]
    }
  });

  const filters = useMemo(
    () => ({
      ...filterProps.filters,
      type,
      dateRange: selectedDate
    }),
    [filterProps.filters, type, selectedDate]
  );

  const handleTypeChange = (val) => {
    setType(val);
  };

  const handleExport = () => {
    exportDonation({
      variables: {
        filter: {
          type,
          dateRange: selectedDate,
          search: filters?.search
        }
      }
    });
  };

  const disabledDate = (current) => {
    return current && current > moment()?.startOf('day');
  };

  const datePickerOnChange = (value) => {
    if (value) {
      setSelectedDate({
        after: dayjs(value?.[0]).startOf('day'),
        before: dayjs(value?.[1]).endOf('day')
      });
    } else {
      setSelectedDate({
        after: null,
        before: null
      });
    }
  };

  return (
    <>
      <PageHeader menu={MODULES?.DONATIONS} />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Donations' }}
        show={{ listModes: false }}
      >
        <RangePicker
          disabledDate={disabledDate}
          onChange={datePickerOnChange}
          className="range-picker"
        />
        <Select
          allowClear
          value={type}
          dropdownMatchSelectWidth={false}
          placeholder="Select type"
          options={TYPE_OPTIONS}
          onChange={handleTypeChange}
        />
        <Tooltip title="Export Donations" placement="left">
          <Button
            className="text-btn d-flex align-center justify-center"
            type="text"
            icon={<Export size={24} weight="thin" />}
            onClick={handleExport}
            loading={exportLoading}
          />
        </Tooltip>
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_DONATIONS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{ columns }}
        limit={20}
      />
    </>
  );
};

export default Donations;
