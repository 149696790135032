import { gql } from '@apollo/client';

export const CANCEL_DONATION = gql`
  mutation cancelDonationAdmin($id: ID!) {
    cancelDonationAdmin(where: { id: $id }) {
      message
    }
  }
`;

export const EXPORT_DONATION = gql`
  mutation RequestDonationsExport($filter: RequestDonationsExportFilter) {
    requestDonationsExport(filter: $filter) {
      jobId
      message
    }
  }
`;
